// utils.ts
export const formatDate = (dateString: string): string => {
    return new Intl.DateTimeFormat('zh-CN', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date(dateString));
  };
  
//   export const formatShortDescription = (description: string): string[] => {
//     return description
//       .split('\n')
//       .map((line) => line.trim())
//       .map((line) => line.charAt(0).toUpperCase() + line.slice(1));
//   };

export const formatShortDescription = (description: string): string[] => {
    if (!description || description.trim() === '') {
      return [];
    }
  
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, 'text/html');
  
    // 提取所有 <li> 元素的文本内容
    let textContent = Array.from(doc.querySelectorAll('li'))
        .map((li) => (li.textContent || '').trim())
        .filter((text) => text !== '');
  
    // 如果文本内容是空的，则说明它是纯文本而非 HTML，直接用 \n 拆分
    if (textContent.length === 0) {
      textContent = description
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line !== '');
    }
  
    return textContent.map((line) => line.charAt(0).toUpperCase() + line.slice(1));
  };
  
  