import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';

export interface JobData {
  id: number;
  title: string;
  company: string;
  classified_location: string;
  classified_industry: string;
  classified_type: string;
  description: string;
  listing_date: string;
  isFeatured?: boolean;
  source: string;
  hour_rate: string;
  salary: string;
  url: string;
  short_description : string;
}

export interface JobFilters {
  title: string;
  location: string;
  type: string;
  industry: string;
  minSalary: number; 
  maxSalary: number;
  page: number;
}

export interface JobApiResponse {
  page: number;
  total_count: number;
  total_pages: number;
  location_counts: Record<string, number>;
  job_type_counts: Record<string, number>;
  industry_counts: Record<string, number>;
  listings: JobData[];
  source_counts: Record<string, number>;
}

interface JobContextType {
  industries: string[];
  jobTypes: string[];
  locations: string[];
  minWageOptions: string[];
  filters: JobFilters;
  setFilters: (filters: JobFilters) => void;
  resetFilters: () => void;
  selectedJob: JobData | null;
  setSelectedJob: (job: JobData) => void;
  fetchJobs: () => Promise<void>;
  fetchJobById: (id: number) => Promise<void>;
  jobData: JobApiResponse | null;
  loading: boolean;
}

const JobContext = createContext<JobContextType | undefined>(undefined);

export const useJobContext = () => {
  const context = useContext(JobContext);
  if (!context) {
    throw new Error("useJobContext must be used within a JobProvider");
  }
  return context;
};

export const JobProvider = ({ children }: { children: ReactNode }) => {

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedJob, setSelectedJob] = useState<JobData | null>(null);
  const [jobData, setJobData] = useState<JobApiResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // API 配置，base_url
  const base_url = 'https://jobapi.spiritx.co.nz';

    // 行业类别列表
    const industries = [
      "工程", 
      "信息与通信技术", 
      "银行与金融服务", 
      "其他", 
      "会计", 
      "社区服务与发展", 
      "教育与培训", 
      "零售与消费品", 
      "科学与技术", 
      "咨询与战略", 
      "医疗保健", 
      "房地产与物业", 
      "广告、艺术与媒体", 
      "制造、运输与物流", 
      "体育与娱乐", 
      "酒店与旅游", 
      "农业、动物与环保", 
      "市场营销与传播", 
      "矿业、资源与能源", 
      "技工与服务", 
      "销售", 
      "行政与办公室支持", 
      "政府与国防", 
      "呼叫中心与客户服务", 
      "建筑", 
      "人力资源与招聘", 
      "保险与养老金", 
      "法律", 
      "设计与建筑", 
      "首席执行官与总经理", 
      "自雇"
  ];
  

  // 工作类型列表
  const jobTypes = [
    '临时工',
    '全职',
    '兼职',
    '合同工',
    '实习',
    '志愿者',
  ];

  const initialFilters: JobFilters = {
    title: '',
    location: '',
    type: '',
    industry: '',
    minSalary: 0,
    maxSalary: 9999,
    page: 1
  };

  const [filters, setFiltersState] = useState<JobFilters>(initialFilters);



  const setFilters = (newFilters: JobFilters) => {
    setSelectedJob(null);
    setFiltersState(newFilters);
    updateUrl(newFilters);
  };

  const resetFilters = () => {
    setSelectedJob(null);
    setFilters(initialFilters);
    navigate({ search: '' });
  };

  const locations = [
    'Auckland', 
    'Bay of Plenty', 
    'Canterbury', 
    'Gisborne', 
    'Hawke\'s Bay', 
    'Manawatu/Whanganui', 
    'Marlborough', 
    'Nelson/Tasman', 
    'Northland', 
    'Otago', 
    'Others', 
    'Southland', 
    'Taranaki', 
    'Waikato', 
    'Wellington', 
    'West Coast'
  ];

  const minWageOptions = ['23', '30', '40', '60', '80', '100'];


  // Load filters from URL on page load
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const newFilters: JobFilters = {
      title: params.get('title') || initialFilters.title,
      location: params.get('location') || initialFilters.location,
      type: params.get('type') || initialFilters.type,
      industry: params.get('industry') || initialFilters.industry,
      minSalary: Number(params.get('minSalary')) || initialFilters.minSalary,
      maxSalary: initialFilters.maxSalary,
      page: Number(params.get('page')) || initialFilters.page,
    };

    setFiltersState(newFilters);
  }, [location.search]);

  const updateUrl = (updatedFilters: JobFilters) => {
    const params = new URLSearchParams();

    if (updatedFilters.title) params.set('title', updatedFilters.title);
    if (updatedFilters.location) params.set('location', updatedFilters.location);
    if (updatedFilters.type) params.set('type', updatedFilters.type);
    if (updatedFilters.industry) params.set('industry', updatedFilters.industry);
    if (updatedFilters.minSalary) params.set('minSalary', updatedFilters.minSalary.toString());
    if (updatedFilters.page) params.set('page', updatedFilters.page.toString());

    navigate({ search: params.toString() });
  };

  const fetchJobs = async () => {
    setLoading(true);
    setJobData(null);
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== '' && value !== 0) {
        searchParams.append(key, String(value));
      }
    });

    try {
      const response = await fetch(`${base_url}/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: searchParams.toString(),
      });

      if (response.ok) {
        const data: JobApiResponse = await response.json();
        if (data.total_pages !== 0 && data.page < 1) {
          // 如果没有任何页数，则将页码设置为1
          setFiltersState((prevFilters) => ({
            ...prevFilters,
            page: 1,
          }));
        } else if (data.total_pages !== 0 && data.page > data.total_pages) {
          // 如果当前页码大于总页码数，则将页码设置为总页码数
          setFiltersState((prevFilters) => ({
            ...prevFilters,
            page: data.total_pages,
          }));
        } else {
          setJobData(data);
        }
      } else {
        console.error('Failed to fetch jobs');
      }
    } catch (error) {
      console.error('An error occurred while fetching jobs:', error);
    } finally {
      setLoading(false); // 完成加载
    }
  };

  const fetchJobById = async (id: number) => {
    setLoading(true);
    try {
      const response = await fetch(`${base_url}/job/${id}`, {
        method: 'GET',
      });

      if (response.ok) {
        const jobData: JobData = await response.json();
        setSelectedJob(jobData);
      } else {
        console.error('Failed to fetch job by ID');
      }
    } catch (error) {
      console.error('An error occurred while fetching job by ID:', error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <JobContext.Provider 
      value={{ 
        industries, 
        jobTypes, 
        locations, 
        minWageOptions, 
        fetchJobs, 
        fetchJobById, 
        jobData, 
        selectedJob, 
        setSelectedJob, 
        filters, 
        setFilters, 
        resetFilters,
        loading 
      }}
    >
      {children}
    </JobContext.Provider>
  );
};
