import React from 'react';
import { Box, Pagination, PaginationItem, Typography  } from '@mui/material';
import JobCard from '../components/JobCard';
import { useJobContext, JobData } from '../contexts/JobContext';
import JobDetail from '../components/JobDetail';
import { useNavigate } from 'react-router-dom';

const JobListing: React.FC = () => {
  const { jobData, filters, setFilters, selectedJob, setSelectedJob, fetchJobById  } = useJobContext();
  const navigate = useNavigate();

  const handleJobClick = (job: JobData) => {
    const isSmallScreen = window.innerWidth < 900;
    fetchJobById(job.id);
    if (isSmallScreen) {
        // navigate(`/job/${job.id}`); // 导航到新页面
        window.open(`/job/${job.id}`, '_blank');
      }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setFilters({ ...filters, page: value });
  };

  const getDisplayedPages = () => {
    const totalPages = jobData?.total_pages || 0;
    const currentPage = filters.page;
    const maxPagesToShow = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = startPage + maxPagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = getDisplayedPages();

  return (
    <Box
        // maxWidth={{ sm: 720, md: 1236, lg: '85vw' }}
        // width={1}
        // margin={'0 auto'}
        // paddingX={2}
        // paddingY={{ xs: 4, sm: 6, md: 8 }}
        sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}
        justifyContent='center'
        width='100%'
    >
        {/* 左侧的Job卡片列表 */}
        <Box 
          width={{ xs: '93%', sm: '93%', md: '30%' }} 
          justifyContent='center'
          alignItems={'center'}
        //   display={'flex'}
        //   flexDirection={'column'}
          sx={{ paddingX: 2, paddingY: 2, overflowY: 'auto' }}
        >
            <Box  justifyContent="center" alignItems="center" mb={2}>
                <Typography variant="subtitle1" align='center'>
                    共找到 {jobData?.total_count} 个职位
                </Typography>
            </Box>

            {jobData?.listings.map((job, index) => (
                <Box key={index} onClick={() => handleJobClick(job)} sx={{ cursor: 'pointer', marginBottom: 2 }}>
                    <JobCard 
                        title={job.title} 
                        company={job.company}
                        location={job.classified_location}
                        category={job.classified_industry}
                        type={job.classified_type}
                        shortDescription={job.short_description}
                        postedTime={job.listing_date}
                        salary={job.salary}
                        isFeatured={job.isFeatured}
                        isSelected={selectedJob?.id === job.id}
                    />
                </Box>
            ))}

            {/* 页码组件 */}
            {jobData?.total_pages && jobData.total_pages > 1 && (
              <Box mt={2} display="flex" justifyContent="center">
                <Pagination
                  count={endPage}
                  page={filters.page}
                  onChange={handlePageChange}
                  siblingCount={1}
                  boundaryCount={1}
                  color="primary"
                  //variant="outlined"
                  shape="rounded"
                  sx={{
                    '& .MuiPaginationItem-root': {
                    borderRadius: '50%', // 让页码按钮变为圆形
                    minWidth: '40px',  // 设置最小宽度，保证是圆形
                    height: '40px', // 设置高度，保证是圆形
                    },}}
                  // renderItem={(item) => {
                  //   const page = item.page ?? 1; // 如果 item.page 为 null，则使用默认值 1
                  //   return <PaginationItem {...item} page={startPage + page - 1} />;
                  // }}
                />
              </Box>
            )}
        </Box>

        {/* 右侧的详细内容显示区域 */}
        <Box 
            width={{ xs: '100%', md: '50%' }}
            sx={{ 
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                paddingX: 2,
            }}
        >
            <Box 
                sx={{ 
                    width: '90%',
                    height: '100vh',
                    position: 'sticky',
                    top: 0,
                    borderRadius: 8,
                    paddingX: 2,
                    overflowY: 'auto',
                    backgroundColor: selectedJob? 'white' : '#f4f6f9'
                }}
            >
                <JobDetail />
            </Box>
        </Box>
    </Box>
  );
};

export default JobListing;
