import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useParams } from 'react-router-dom';
import { useJobContext } from '../contexts/JobContext';
import JobDetail from '../components/JobDetail';

const JobDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); 
  const { fetchJobById, selectedJob } = useJobContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJob = async () => {
      if (id) {
        await fetchJobById(parseInt(id)); // 调用 fetchJobById 方法获取数据
        setLoading(false);
      }
    };

    fetchJob();
  }, [id]);

  return (
    <Box
        display="flex"
        justifyContent="center"  // 水平居中
        //alignItems="center"  // 垂直居中
        width="100%"  // 确保 Box 占满可用空间
        minHeight="80vh"  // 可选，确保内容垂直居中
        marginX="auto"  // 左右自动 margin 以实现居中
        paddingBottom={{ xs: 4, sm: 6, md: 8 }}
    >

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
          <Typography variant="h6" ml={2}>
            搜索中...
          </Typography>
        </Box>
      ) : !selectedJob? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="200px">
          <SearchOffIcon sx={{ fontSize: 64, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h5" color="textSecondary">
            未找到匹配的工作。请返回搜索页进行搜索。
          </Typography>
        </Box>
      ) : (
        <Box width={{ xs: '90%', sm: '80%', md: '70%', lg: '60%' }} margin="0 auto">
            <JobDetail />
        </Box>
      )}
    </Box>
  );
};

export default JobDetailPage;
