import React, { useState } from 'react';
import { Box, Button, Grid, MenuItem, Select, TextField, Typography, InputLabel, FormControl, InputAdornment, Chip, IconButton } from '@mui/material';
import { Search, RestartAltOutlined, Clear } from '@mui/icons-material';
import { useJobContext } from '../contexts/JobContext';

const SearchArea: React.FC = () => {
  const { jobData, filters, setFilters, industries, jobTypes, locations, minWageOptions, resetFilters } = useJobContext();

  // 本地状态用于管理用户输入
  const [searchTitle, setSearchTitle] = useState(filters.title);

  const handleFilterChange = (field: keyof typeof filters, value: string | number) => {
    const newFilters = {
      ...filters,
      [field]: value === '任意地点' || value === '任意行业' || value === '任意工作类型' || value === '任意最低工资' ? '' : value,
    };
    setFilters(newFilters);
  };

  const handleSearch = () => {
    handleFilterChange('title', searchTitle);
  };

  const handleReset = () => {
    resetFilters(); // 重置所有过滤器为初始值
    setSearchTitle(''); // 重置本地的搜索框状态
  };

  const handleClear = () => {
    setSearchTitle(''); // 清空输入框
    handleFilterChange('title', ''); // 清空过滤器中的title
  };

  return (
    <Box sx={{ p: 3, bgcolor: 'background.default', borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h5" gutterBottom color="primary">
        搜索工作
      </Typography>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={9} md={10} lg={10.5} xl={11}>
          <TextField
            fullWidth
            label="搜索职位"
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(); // 按下回车键时调用 handleSearch 函数
              }
            }}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTitle && (
                    <IconButton
                      size="small"
                      onClick={handleClear}
                      sx={{ marginRight: 1 }}
                    >
                      <Clear />
                    </IconButton>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Search />}
                    onClick={handleSearch}
                    sx={{ paddingX: 5 }}
                  >
                    搜索
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2} lg={1.5} xl={1}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            startIcon={<RestartAltOutlined />}
            onClick={handleReset}
            sx={{
              height: '100%',
              whiteSpace: 'nowrap', // 防止文字换行
              minWidth: '80px', // 可选，设置按钮的最小宽度
            }}
          >
            重置
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>地点</InputLabel>
            <Select
              value={filters.location || '任意地点'}
              onChange={(e) => handleFilterChange('location', e.target.value as string)}
              label="地点"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '50vh',
                  },
                },
              }}
              sx={{
                borderRadius: '24px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#005bb5', // 边框颜色
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#003f87', // Hover时的边框颜色
                },
                '& .MuiSvgIcon-root': {
                  color: '#005bb5', // 下拉箭头的颜色
                },
              }}
              renderValue={(selected) => (
                <Box display="flex" justifyContent="space-between">
                  {selected}
                  {/* {jobData?.location_counts[selected as string] && (
                    <Chip
                      label={jobData.location_counts[selected as string]}
                      size="small"
                      variant='outlined'
                      color='primary'
                      sx={{ ml: 1}} // 低调颜色
                    />
                  )} */}
                </Box>
              )}
            >
              <MenuItem value="任意地点">
                <em>任意地点</em>
              </MenuItem>
              {locations.map((location) => (
                <MenuItem 
                  key={location} 
                  value={location}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 91, 181, 0.1)', // Hover时的背景颜色
                    },
                  }}
                >
                  <Box display="flex" justifyContent="space-between" width="100%">
                    {location}
                    {jobData?.location_counts[location] && (
                      <Chip
                        label={jobData.location_counts[location]}
                        size="small"
                        variant='outlined'
                        color='primary'
                        sx={{ ml: 1}} // 低调颜色
                      />
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>行业</InputLabel>
            <Select
              value={filters.industry || '任意行业'}
              onChange={(e) => handleFilterChange('industry', e.target.value as string)}
              label="行业"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '50vh',
                  },
                },
              }}
              sx={{
                borderRadius: '24px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#005bb5', // 边框颜色
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#003f87', // Hover时的边框颜色
                },
                '& .MuiSvgIcon-root': {
                  color: '#005bb5', // 下拉箭头的颜色
                },
              }}
              renderValue={(selected) => (
                <Box display="flex" justifyContent="space-between">
                  {selected}
                  {/* {jobData?.industry_counts[selected as string] && (
                    <Chip
                      label={jobData.industry_counts[selected as string]}
                      size="small"
                      variant='outlined'
                      color='primary'
                      sx={{ ml: 1}}// 低调颜色
                    />
                  )} */}
                </Box>
              )}
            >
              <MenuItem value="任意行业">
                <em>任意行业</em>
              </MenuItem>
              {industries.map((industry) => (
                <MenuItem 
                 key={industry} 
                 value={industry}
                 sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 91, 181, 0.1)', // Hover时的背景颜色
                  },
                }}
                >
                  <Box display="flex" justifyContent="space-between" width="100%">
                    {industry}
                    {jobData?.industry_counts[industry] && (
                      <Chip
                        label={jobData.industry_counts[industry]}
                        size="small"
                        variant='outlined'
                        color='primary'
                        sx={{ ml: 1}} // 低调颜色
                      />
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>工作类型</InputLabel>
            <Select
              value={filters.type || '任意工作类型'}
              onChange={(e) => handleFilterChange('type', e.target.value as string)}
              label="工作类型"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '50vh',
                  },
                },
              }}
              sx={{
                borderRadius: '24px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#005bb5', // 边框颜色
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#003f87', // Hover时的边框颜色
                },
                '& .MuiSvgIcon-root': {
                  color: '#005bb5', // 下拉箭头的颜色
                },
              }}
              renderValue={(selected) => (
                <Box display="flex" justifyContent="space-between">
                  {selected}
                  {/* {jobData?.job_type_counts[selected as string] && (
                    <Chip
                      label={jobData.job_type_counts[selected as string]}
                      size="small"
                      variant='outlined'
                      color='primary'
                      sx={{ ml: 1}}
                    />
                  )} */}
                </Box>
              )}
            >
              <MenuItem value="任意工作类型">
                <em>任意工作类型</em>
              </MenuItem>
              {jobTypes.map((jobType) => (
                <MenuItem 
                  key={jobType} 
                  value={jobType}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 91, 181, 0.1)', // Hover时的背景颜色
                    },
                  }}
                >
                  <Box display="flex" justifyContent="space-between" width="100%">
                    {jobType}
                    {jobData?.job_type_counts[jobType] && (
                      <Chip
                        label={jobData.job_type_counts[jobType]}
                        size="small"
                        variant='outlined'
                        color='primary'
                        sx={{ ml: 1}} // 低调颜色
                      />
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>最低工资</InputLabel>
            <Select
              value={filters.minSalary !== undefined && filters.minSalary > 0 ? filters.minSalary.toString() : '任意最低工资'}
              // onChange={(e) => handleFilterChange('minSalary', parseInt(e.target.value))}
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value === '任意最低工资' ? 0 : parseInt(value);
                handleFilterChange('minSalary', parsedValue);
              }}
              label="最低工资"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '50vh',
                  },
                },
              }}
              sx={{
                borderRadius: '24px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#005bb5', // 边框颜色
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#003f87', // Hover时的边框颜色
                },
                '& .MuiSvgIcon-root': {
                  color: '#005bb5', // 下拉箭头的颜色
                },
              }}
            >
              <MenuItem value="任意最低工资">
                <em>任意最低工资</em>
              </MenuItem>
              {minWageOptions.map((wage) => (
                <MenuItem 
                  key={wage} 
                  value={wage}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 91, 181, 0.1)', // Hover时的背景颜色
                    },
                  }}
                >
                  {`$${wage}/小时`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchArea;