import React, { useEffect, useState, useRef } from 'react';
import { Box, Divider, Typography, Button, Paper, Stack, CircularProgress  } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InboxIcon from '@mui/icons-material/Inbox';
import LinkIcon from '@mui/icons-material/Link';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useJobContext } from '../contexts/JobContext';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { formatDate } from '../utils/utils';

const JobDetail: React.FC = () => {
  const { selectedJob, loading  } = useJobContext();
  const [isSticky, setIsSticky] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const paperRef = useRef<HTMLDivElement>(null);

  const handleApplyClick = () => {
    if (selectedJob?.url) {
      window.open(selectedJob.url, '_blank');
    }
  };

  useEffect(() => {
    if (!loading) {
      // 在loading变为false后延迟500毫秒显示内容
      const timer = setTimeout(() => {
        setShowContent(true);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setShowContent(false);
    }
  }, [loading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting) {
            setIsSticky(true);
            setIsVisible(true); // 当变为sticky时，触发动画
          } else {
            setIsSticky(false); // 当不再为sticky时，移除动画
            setTimeout(() => setIsVisible(false), 400);
          }
        },
        { threshold: 0 }
      );

    const refCurrent = paperRef.current;
    if (refCurrent) {
      observer.observe(refCurrent);
    }

    return () => {
      if (refCurrent) {
        observer.unobserve(refCurrent);
      }
    };
  }, [selectedJob]);

  if (loading || !showContent) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!selectedJob) {
    return (
      <Box justifyContent='center'>
        <Typography variant="h4" color="text.secondary" align='center' marginTop={20}>
          请选择一个职位以查看详细信息
        </Typography>
        {/* <Box marginTop={5}>
          <img 
            src="https://via.placeholder.com/300" // 替换为实际的图片URL
            alt="No job selected" 
            style={{ maxWidth: '100%', height: 'auto', marginTop: 20 }} 
          />
        </Box> */}
      </Box>
    );
  }

  const formattedDate = formatDate(selectedJob.listing_date);

  return (
    <Box>

      {/* 上半部分 */}
        <Box sx={{
            position: 'sticky', 
            top: 0, 
            //display: isSticky? 'flex' : 'block',
            zIndex: 10, 
            backgroundColor: 'white',
            //maxHeight: 100,
            paddingX: 5,
            //opacity: isSticky ? 1 : 0,
            visibility: isSticky ? 'visible' : 'hidden',
            transform: isSticky ? 'translateY(0)' : 'translateY(-100%)',
            transition: 'transform 0.5s ease-in-out, visibility 0.5s ease-in-out',
            //transition: 'transform 0.4s ease-in-out, opacity 0.4s ease-in-out, visibility 0.4s ease-in-out',
          }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" paddingY={1.5}>
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#005bb5', textAlign: 'left' }}>
                    {selectedJob.title}
                    </Typography>
                    { selectedJob.company && <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                    <BusinessIcon fontSize="small" sx={{ color: '#00796b' }} />
                    <Typography variant="body2" color="text.secondary">{selectedJob.company}</Typography>
                    </Stack>}
                </Box>
                <Box alignItems='center'>
                    <Button variant="contained" color="primary" onClick={handleApplyClick} sx={{ alignSelf: 'flex-end' }}>
                        立即申请
                    </Button>
                </Box>
            </Stack>
            <Divider variant="middle" sx={{ borderWidth: '1.5px'}} />
        </Box>

        <Box
            ref={paperRef}
            sx={{
            padding: 3,
            marginBottom: 2,
            left: 'auto',
            right: 'auto',
            backgroundColor: 'white',
            // boxShadow: isSticky ? '0px 2px 10px rgba(0,0,0,0.1)' : 'none',
            // width: isSticky ? '100%' : '50vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            }}
        >
            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#005bb5', textAlign: 'left', lineHeight: 1.2 }}>
            {selectedJob.title}
            </Typography>
            </Stack>
            { selectedJob.company && <Stack direction="row" alignItems="center" spacing={1} mb={1}>
            <BusinessIcon fontSize="small" sx={{ color: '#00796b' }} />
            <Typography variant="subtitle1">{selectedJob.company}</Typography>
            </Stack>}
            { selectedJob.classified_location && <Stack direction="row" alignItems="center" spacing={1} mb={1}>
            <LocationOnIcon fontSize="small" sx={{ color: '#00796b' }} />
            <Typography variant="body2" color="text.secondary">工作地点: {selectedJob.classified_location}</Typography>
            </Stack>}
            { selectedJob.classified_type && <Stack direction="row" alignItems="center" spacing={1} mb={1}>
            <WorkIcon fontSize="small" sx={{ color: '#00796b' }} />
            <Typography variant="body2" color="text.secondary">职位类型: {selectedJob.classified_type}</Typography>
            </Stack>}
            {selectedJob.salary && <Stack direction="row" alignItems="center" spacing={1} mb={1}>
              <AttachMoneyIcon fontSize="small" sx={{ color: '#00796b' }} />
              <Typography variant="body2" color="text.secondary">
                薪资: {selectedJob.salary}
              </Typography>
            </Stack>}
            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
              <AccessTimeIcon fontSize="small" sx={{ color: '#00796b' }} />
              <Typography variant="body2" color="text.secondary">
                发布于: {formattedDate}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
              <LinkIcon fontSize="small" sx={{ color: '#00796b' }} />
              <Typography variant="body2" color="text.secondary">来源: {selectedJob.source}</Typography>
            </Stack>
            <Button variant="contained" color="primary" onClick={handleApplyClick} sx={{ marginTop: 2 }}>立即申请</Button>
        </Box>

      {/* 下半部分 */}
      {/* <Box sx={{ padding: 1 }}>
        <Typography variant="h6"  paragraph sx={{textAlign: 'left'}}>{selectedJob.description}</Typography>
      </Box> */}
      {selectedJob.description ? (
        <Box
          sx={{
            mt: 6,
            wordWrap: 'break-word', // 自动换行
            wordBreak: 'break-all', // 在长单词或URL处断行
            overflow: 'hidden', // 隐藏超出容器的内容
          }}
          dangerouslySetInnerHTML={{
            __html: selectedJob.description
              .split('\n')
              .map(line => `<p>${line.trim()}</p>`)
              .join(''),
          }}
        />
      ) : (
        <Box
          sx={{
            mt: 6,
            textAlign: 'center',
            padding: 3,
            backgroundColor: '#f5f5f5',
            borderRadius: 2,
          }}
        >
          <InfoOutlinedIcon sx={{ fontSize: 40, color: 'text.secondary', mb: 1 }} />
          <Typography variant="body1" color="text.secondary">
            没有可用的职位描述。
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default JobDetail;
