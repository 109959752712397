import React from 'react';
import { Card, CardContent, Typography, Chip, Stack, Box } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { relative } from 'path';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { formatDate, formatShortDescription } from '../utils/utils';

interface JobCardProps {
  title: string;
  company: string;
  location: string;
  category: string;
  type: string;
  salary: string;
  shortDescription: string;
  postedTime: string;
  isFeatured?: boolean;
  isSelected?: boolean;
}

const JobCard: React.FC<JobCardProps> = ({ title, company, location, category, type, salary, shortDescription, postedTime, isFeatured, isSelected }) => {

  const formattedDate = formatDate(postedTime);
  const shortDescriptionLines = formatShortDescription(shortDescription);

  return (
    <Card 
      sx={{
        marginBottom: 3,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        border: isSelected? 3 : 1,
        borderColor: isSelected? '#00796b' : '#cccccc',
        boxShadow: isSelected ? '0px 10px 20px rgba(0, 0, 0, 0.2)' : '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        background: 'linear-gradient(135deg, #ffffff 0%, #f4f6f9 100%)',
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
          border: 2,
          borderColor: '#00796b',

        }
      }}
    >
      <CardContent>
        <Stack direction="column" spacing={1} mb={1}>
          {isFeatured && (
            <Box display="flex" justifyContent="flex-end">
              <Chip 
                label="为您推荐" 
                color="secondary" 
                size='small'
                sx={{
                  backgroundColor: '#e0f7fa',
                  color: '#00796b',
                  fontWeight: 'bold',
                  marginTop: -1,
                  borderRadius: 2
                }} 
              />
            </Box>
          )}
          <Typography variant="h6" align='left' sx={{ fontWeight: 'bold', color: '#005bb5' }}>
            {title}
          </Typography>
        </Stack>
        { company && <Stack direction="row" alignItems="center" spacing={1} mb={1}>
          <BusinessIcon fontSize="small" sx={{ color: '#00796b' }} />
          <Typography variant="body2" color="text.secondary">{company}</Typography>
        </Stack>}
        { location && <Stack direction="row" alignItems="center" spacing={1} mb={1}>
          <LocationOnIcon fontSize="small" sx={{ color: '#00796b' }} />
          <Typography variant="body2" color="text.secondary">{location}</Typography>
        </Stack>}
        { category && <Stack direction="row" alignItems="center" spacing={1} mb={1}>
          <WorkIcon fontSize="small" sx={{ color: '#00796b' }} />
          <Typography variant="body2" color="text.secondary">{category}</Typography>
          <Chip label={type} size="small" color="primary" variant="outlined" sx={{ marginLeft: 1 }} />
        </Stack>}
        { salary && <Stack direction="row" alignItems="center" spacing={1} mb={1}>
          <AttachMoneyIcon fontSize="small" sx={{ color: '#00796b' }} />
          <Typography variant="body2" color="text.secondary">
            {salary}
          </Typography>
        </Stack>}
        { shortDescription && shortDescriptionLines.length > 0 && (
          <Box my={2}>
            {shortDescriptionLines.map((line, index) => (
              <Typography
                key={index}
                variant="body2"
                color="text.secondary"
                sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
              >
                • {line}
              </Typography>
            ))}
        </Box>)}
      </CardContent>
      { postedTime && <Stack direction="row" alignItems="center" spacing={1}>
          <AccessTimeIcon fontSize="small" sx={{ color: '#00796b' }} />
          <Typography variant="body2" color="text.secondary">
            发布于: {formattedDate}
          </Typography>
        </Stack>}
    </Card>
  );
};

export default JobCard;
