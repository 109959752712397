import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import JobListing from '../components/JobListing';
import SearchArea from '../components/SearchArea';
import { useJobContext } from '../contexts/JobContext';
import { useDebounce } from 'use-debounce';

const JobSearchPage: React.FC = () => {
  const { filters, resetFilters, fetchJobs, jobData } = useJobContext();
  const [loading, setLoading] = useState(true);
  const [debouncedFilters] = useDebounce(filters, 500); // 500ms 延迟

  // 用于监听 debouncedFilters 变化的请求
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchJobs();  // 确保 fetchJobs 使用的是防抖后的 filters
      setLoading(false);
    };

    fetchData();  // debouncedFilters 变化时执行请求
  }, [debouncedFilters]);  // 监听 debouncedFilters 的变化

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      //maxWidth={{ xs: '90vw', sm: '85vw', md: '90vw', lg: '85vw' }}
      width='100%'
      //margin={'0 auto'}
      //paddingX={2}
      // paddingY={{ xs: 4, sm: 6, md: 8 }}
    >
      <Box
        mb={4}
        paddingX={{ xs: '5%', sm: '5%', md: '10%', lg: '15%', xl: '15%' }}
      >
       
          <SearchArea />

      </Box>


      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
          <Typography variant="h6" ml={2}>
            搜索中...
          </Typography>
        </Box>
      ) : jobData?.listings.length === 0 ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="200px">
          <SearchOffIcon sx={{ fontSize: 64, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h5" color="textSecondary">
            未找到匹配的工作。请调整您的搜索条件。
          </Typography>
        </Box>
      ) : (
        <JobListing />
      )}
    </Box>
  );
};

export default JobSearchPage;
